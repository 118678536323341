import {Button, Form, FormProps, InputNumber, Select} from 'antd';
import React, {useMemo, useState} from 'react';
import {IOrder} from '../../../../interfaces';
import {PlusSquareOutlined} from '@ant-design/icons';

export type PaymentCreateInvoiceLine = {
  paymentType: 'payment' | 'payout';
  orderItemId: string;
  invoiceLineType: string;
  amount: string;
};

type FormValues = {
  amount: number;
  invoice_line_type: string;
  order_item: string;
};

const PAYOUT_INVOICE_LINE_TYPES = {
  DELIVERY_DEDUCTION: 470,
};

const PAYOUT_ORDER_ITEM_INVOICE_LINE_TYPES = {
  ITEM_DEDUCTION: 400,
  STORE_DELIVERY_DEDUCTION: 410,
  TAX_RESERVE_DEDUCTION: 420,
  CUSTOMS_DUTIES_DEDUCTION: 430,
  COMMISSION_DEDUCTION: 440,
  QEMPO_DEDUCTION: 450,
  WARRANTY_DEDUCTION: 460,
};

const PAYMENT_INVOICE_LINE_TYPES = {
  DELIVERY_DISPUTE_SETTLEMENT: 570,
};

const PAYMENT_ORDER_ITEM_INVOICE_LINE_TYPES = {
  ITEM_DISPUTE_SETTLEMENT: 500,
  STORE_DELIVERY_DISPUTE_SETTLEMENT: 510,
  TAX_RESERVE_DISPUTE_SETTLEMENT: 520,
  CUSTOMS_DUTIES_DISPUTE_SETTLEMENT: 530,
  COMMISSION_DISPUTE_SETTLEMENT: 540,
  QEMPO_DISPUTE_SETTLEMENT: 550,
  WARRANTY_DISPUTE_SETTLEMENT: 560,
};

export const PaymentCreateInvoiceLineForm: React.FC<{
  order?: IOrder;
  paymentType: 'payment' | 'payout';
  onAddInvoiceLine: (value: PaymentCreateInvoiceLine) => void;
  disabled?: boolean;
}> = ({order, paymentType, onAddInvoiceLine, disabled = false}) => {
  const [form] = Form.useForm();
  const [hasOrderItem, setHasOrderItem] = useState(false);

  const invoiceLineTypes = useMemo(() => {
    if (paymentType === 'payment') {
      if (hasOrderItem) return PAYMENT_ORDER_ITEM_INVOICE_LINE_TYPES;
      else return PAYMENT_INVOICE_LINE_TYPES;
    } else if (paymentType === 'payout') {
      if (hasOrderItem) return PAYOUT_ORDER_ITEM_INVOICE_LINE_TYPES;
      else return PAYOUT_INVOICE_LINE_TYPES;
    }
  }, [paymentType, hasOrderItem]);

  const onFinish: FormProps<FormValues>['onFinish'] = (values) => {
    onAddInvoiceLine({
      paymentType,
      orderItemId: values.order_item,
      invoiceLineType: values.invoice_line_type,
      amount: values.amount.toFixed(2),
    });
    form.resetFields();
    setHasOrderItem(false);
  };

  const onFinishFailed: FormProps<FormValues>['onFinishFailed'] = (
    errorInfo
  ) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 14}}
      layout="horizontal"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={disabled}
    >
      <Form.Item name="order_item" label="Order Item">
        <Select
          allowClear
          onSelect={() => {
            setHasOrderItem(true);
            form.setFieldValue('invoice_line_type', null);
          }}
          onClear={() => {
            setHasOrderItem(false);
            form.setFieldValue('invoice_line_type', null);
          }}
        >
          {order &&
            order.items
              .filter((i) => i.state !== 'CANCELED')
              .map((i) => (
                <Select.Option key={i.id} value={i.id}>
                  ({i.id}) {i.name}
                </Select.Option>
              ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="invoice_line_type"
        label="Invoice Line Type"
        rules={[{required: true}]}
      >
        <Select>
          {invoiceLineTypes &&
            Object.keys(invoiceLineTypes).map((k) => (
              <Select.Option key={k} value={k}>
                {k}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="amount"
        label="Amount"
        rules={[
          {required: true},
          /*paymentType === 'payment'
            ? {min: 0, message: 'Amount has to be positive'}
            : {max: 0, message: 'Amount has to be negative'},*/
          {
            message:
              paymentType === 'payment'
                ? 'Amount has to be positive'
                : 'Amount has to be negative',
            validator: async (_, value) => {
              if (paymentType === 'payment' && value < 0) {
                return Promise.reject(new Error('Amount has to be positive'));
              }

              if (paymentType === 'payout' && value > 0) {
                return Promise.reject(new Error('Amount has to be negative'));
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <InputNumber<number>
          addonBefore="$"
          width="100%"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) =>
            value?.replace(/\$\s?|(,*)/g, '') as unknown as number
          }
        />
      </Form.Item>
      <Button
        type="default"
        size="large"
        block
        icon={<PlusSquareOutlined />}
        iconPosition="start"
        htmlType="submit"
      >
        Add payment invoice line
      </Button>
    </Form>
  );
};
