import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IResourceComponentsProps} from '@refinedev/core';
import {useStepsForm, Create, SaveButton} from '@refinedev/antd';
import {
  ICategory,
  ICourier,
  IOrder,
  IAdmin1,
  IAdmin2,
  IAdmin3,
} from '../../../interfaces';
import {
  Flex,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Steps,
  Descriptions,
  Tag,
  Divider,
} from 'antd';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';
import {CourierService} from 'modules/courier/service';
import {CategoryService} from 'modules/category/service';
import {GeographyService} from 'modules/geography/service';
import {
  MinusOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {UserService} from 'modules/user/service';
import {OrderItemCreate} from '../components/order-item';
import {OrderGeography} from '../components/order-geography';

export const OrderCreate: React.FC<IResourceComponentsProps> = () => {
  const courierService = new CourierService(axiosInstance as AxiosInstance);
  const categoryService = new CategoryService(axiosInstance as AxiosInstance);
  const userService = new UserService(axiosInstance as AxiosInstance);
  const geographyService = new GeographyService(axiosInstance as AxiosInstance);

  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    queryResult,
  } = useStepsForm<IOrder>();

  const navigate = useNavigate();

  const {form} = formProps;

  const [serviceType, setServiceType] = useState<string>('LITE');
  const [numberOrderItems, setNumberOrderItems] = useState<number>(0);

  const [user, setUser] = useState<any>();
  const [error, setError] = useState<any>(null);

  const [userId, setUserId] = useState<string>('');

  const [couriers, setCouriers] = useState<{label: string; value: string}[]>();
  const [categories, setCategories] = useState<ICategory[]>();

  const [shippingAddressOptions, setShippingAddressOptions] =
    useState<{label: string; value: string}[]>();
  const [shippingAddressList, setShippingAddressList] = useState<any>();

  const [admins1, setAdmins1] = useState<{label: string; value: string}[]>();
  const [admins2, setAdmins2] = useState<{label: string; value: string}[]>();
  const [admins3, setAdmins3] = useState<{label: string; value: string}[]>();

  const [country, setCountry] = useState('US');

  const getAdmins1 = useCallback(
    (country: string) => {
      setAdmins1([]);
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin1(country).then((admins1) => {
        setAdmins1(
          admins1.map((admin1: IAdmin1) => ({
            label: admin1.name,
            value: admin1.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins2 = useCallback(
    (admin1: string) => {
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin2(admin1).then((admins2) => {
        setAdmins2(
          admins2.map((admin2: IAdmin2) => ({
            label: admin2.name,
            value: admin2.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins3 = useCallback(
    (admin2: string) => {
      geographyService.getAdmin3(admin2).then((admins2) =>
        setAdmins3(
          admins2.map((admin3: IAdmin3) => ({
            label: admin3.name,
            value: admin3.id,
          }))
        )
      );
    },
    [geographyService]
  );

  const getCouriers = useCallback(() => {
    courierService.getCouriers().then((couriers) =>
      setCouriers(
        couriers.map((courier: ICourier) => ({
          label: courier.courierSetting.courierAddress.name,
          value: courier.id,
        }))
      )
    );
  }, [courierService]);

  const getCategories = useCallback(() => {
    categoryService.getCategories().then((categories) => {
      setCategories(categories);
    });
  }, [categoryService]);

  const listUserShippingAddress = () => {
    geographyService.listShippingAddressByUserId(user.id).then((data) => {
      setShippingAddressOptions(
        data.map((shippingAddress: any, i: number) => ({
          label: `${shippingAddress.address1} ${shippingAddress.address2}, ${shippingAddress.admin1.name}, ${shippingAddress.admin2.name}, ${shippingAddress.admin3.name} `,
          value: i,
        }))
      );
      setShippingAddressList(data);
      console.log(data);
    });
  };

  useEffect(() => {
    if (user) listUserShippingAddress();
  }, [user]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getCouriers();
  }, [serviceType]);

  useEffect(() => {
    getAdmins1('US');
  }, [setAdmins1]);

  const getUserById = () => {
    userService
      .getUser(userId)
      .then((res) => {
        setUser(res);
        setError(null);
      })
      .catch((err) => {
        setError(err);
        setUser(null);
      });
  };

  const formList = [
    <Row key="User" gutter={20}>
      <Col xs={0} sm={0} md={4} />
      <Col xs={24} sm={24} md={16}>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item name="purchaserId">
              <Input
                placeholder="U-#######"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={getUserById}
              style={{width: '100%'}}
            >
              Search
            </Button>
          </Col>
        </Row>
        <p></p>
        {user ? (
          <>
            <Tag icon={<CheckCircleOutlined />} color="success">
              User information found!
            </Tag>
            <p></p>
            <Descriptions title="User Information">
              <Descriptions.Item label="First Name">
                {user['firstName']}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {user['lastName']}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {user['email']}
              </Descriptions.Item>
              <Descriptions.Item label="ID">{user['id']}</Descriptions.Item>
            </Descriptions>
          </>
        ) : error ? (
          <>
            <p></p>
            <Tag icon={<CloseCircleOutlined />} color="error">
              User information not found
            </Tag>

            <Tag
              icon={<PlusOutlined />}
              color="processing"
              onClick={() => navigate('/users/create')}
              style={{cursor: 'pointer'}}
            >
              Create new User
            </Tag>
          </>
        ) : (
          <p></p>
        )}
        <p></p>
      </Col>
    </Row>,
    <Row key="Shipping Address" gutter={20}>
      <Col xs={0} sm={0} md={4} />
      <Col xs={24} sm={24} md={16}>
        {user && (
          <Form.Item label="User Shipping Addresses">
            <Select
              allowClear
              placeholder="Shipping Addresses"
              options={shippingAddressOptions}
              showSearch
              filterOption={(inputValue, option) => {
                if (option && typeof option.label === 'string') {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
              onChange={(shippingAddressIndex: number) => {
                const shippingAddress =
                  shippingAddressList[shippingAddressIndex];
                const countryInput = shippingAddress
                  ? shippingAddress.country.code
                  : 'US';
                const admin1Input = shippingAddress?.admin1.id;
                const admin2Input = shippingAddress?.admin2.id;

                setCountry(countryInput);
                getAdmins1(countryInput);
                if (admin1Input) getAdmins2(admin1Input);
                if (admin2Input) getAdmins3(admin2Input);

                form?.setFieldsValue({
                  shippingAddressId: shippingAddress?.id,
                  'shippingAddress.firstName': shippingAddress?.firstName,
                  'shippingAddress.lastName': shippingAddress?.lastName,
                  'shippingAddress.idNumber': shippingAddress?.idNumber,
                  'shippingAddress.phoneCountry':
                    shippingAddress?.phoneCountry.code,
                  'shippingAddress.phone': shippingAddress?.phone,
                  'shippingAddress.country': countryInput,
                  'shippingAddress.admin1Id': shippingAddress?.admin1.id,
                  'shippingAddress.admin2Id': shippingAddress?.admin2.id,
                  'shippingAddress.admin3Id': shippingAddress?.admin3.id,
                  'shippingAddress.address1': shippingAddress?.address1,
                  'shippingAddress.address2': shippingAddress?.address2,
                  'shippingAddress.zipCode': shippingAddress?.zipCode,
                });
              }}
            />
          </Form.Item>
        )}
        <Form.Item name="shippingAddressId" style={{display: 'none'}}>
          <Input />
        </Form.Item>

        <Form.Item
          label="First Name"
          name="shippingAddress.firstName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="shippingAddress.lastName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Id Number"
          name="shippingAddress.idNumber"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row key="settings" gutter={20}>
          <Col xs={8}>
            <Form.Item
              label="Phone country"
              name="shippingAddress.phoneCountry"
              rules={[{required: true}]}
            >
              <Select
                allowClear
                placeholder="Phone Country"
                options={[
                  {label: 'United States', value: 'US'},
                  {label: 'Peru', value: 'PE'},
                  {label: 'Colombia', value: 'CO'},
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={16}>
            <Form.Item
              label="Phone"
              name="shippingAddress.phone"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Country"
          name="shippingAddress.country"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Country"
            options={[
              {label: 'United States', value: 'US'},
              {label: 'Peru', value: 'PE'},
              {label: 'Colombia', value: 'CO'},
            ]}
            onChange={(country: string) => {
              setCountry(country);
              getAdmins1(country);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Admin1"
          name="shippingAddress.admin1Id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Admin1"
            options={admins1}
            showSearch
            filterOption={(inputValue, option) => {
              if (option && typeof option.label === 'string') {
                return (
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                );
              }

              return false;
            }}
            onChange={(admin1: number) => {
              getAdmins2(`${admin1}`);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Admin2"
          name="shippingAddress.admin2Id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Admin2"
            options={admins2}
            disabled={!admins2 || admins2.length === 0}
            showSearch
            filterOption={(inputValue, option) => {
              if (option && typeof option.label === 'string') {
                return (
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                );
              }

              return false;
            }}
            onChange={(admin2: number) => {
              getAdmins3(`${admin2}`);
            }}
          />
        </Form.Item>

        {country !== 'US' && (
          <Form.Item
            label="Admin3"
            name="shippingAddress.admin3Id"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Admin3"
              options={admins3}
              disabled={!admins3 || admins3.length === 0}
              showSearch
              filterOption={(inputValue, option) => {
                if (option && typeof option.label === 'string') {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  );
                }

                return false;
              }}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Address1"
          name="shippingAddress.address1"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address2" name="shippingAddress.address2">
          <Input />
        </Form.Item>
        <Form.Item label="ZipCode" name="shippingAddress.zipCode">
          <Input />
        </Form.Item>
      </Col>
    </Row>,

    <Row key="ServiceType" gutter={20}>
      <Col xs={0} sm={0} md={4} />
      <Col xs={24} sm={24} md={16}>
        <Form.Item label="Service Type" name="serviceType">
          <Radio.Group
            onChange={(event) => {
              setServiceType(event.target.value);
            }}
            value={serviceType}
          >
            <Radio value={'FULL'} disabled>
              FULL
            </Radio>
            <Radio value={'LITE'}>LITE</Radio>
          </Radio.Group>
        </Form.Item>

        {serviceType == 'LITE' && (
          <Form.Item
            label="Courier"
            name="courierId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Courier"
              options={couriers}
              showSearch
              filterOption={(inputValue, option) => {
                if (option && typeof option.label === 'string') {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  );
                }
                return false;
              }}
            />
          </Form.Item>
        )}

        {serviceType == 'FULL' && (
          <>
            <OrderGeography
              title={'Flight Departure'}
              formItemNames={{
                country: 'flight.departureCountry',
                admin1Id: 'flight.departureAdmin1',
                admin2Id: 'flight.departureAdmin2',
                admin3Id: 'flight.departureAdmin3',
              }}
            />

            <OrderGeography
              title={'Flight Arrival'}
              formItemNames={{
                country: 'flight.arrivalCountry',
                admin1Id: 'flight.arrivalAdmin1',
                admin2Id: 'flight.arrivalAdmin2',
                admin3Id: 'flight.arrivalAdmin3',
              }}
            />
            <Form.Item
              label="Date"
              name="flight.date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </>
        )}
      </Col>
    </Row>,
    <Row key="Order Details" gutter={20}>
      <Col xs={0} sm={0} md={4} />
      <Col xs={24} sm={24} md={16}>
        <Divider orientation="center">Details</Divider>

        <Form.Item
          label="Before Date"
          name="beforeDate"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item label="Coupon Amount" name="invoiceData.coupon">
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Coupon Name" name="invoiceData.couponName">
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Mile"
          name="invoiceData.lastMile"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Divider orientation="center">Order Items</Divider>

        <Flex gap="small">
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setNumberOrderItems(numberOrderItems + 1)}
          ></Button>

          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<MinusOutlined />}
            onClick={() => setNumberOrderItems(numberOrderItems - 1)}
          ></Button>
        </Flex>

        <p></p>

        <Flex gap="small" vertical>
          {Array.from({length: numberOrderItems}, (_, index) => (
            <OrderItemCreate
              key={index}
              categories={categories}
              orderItemIndex={index}
            />
          ))}
        </Flex>
      </Col>
    </Row>,
  ];

  return (
    <Create
      isLoading={queryResult?.isFetching}
      footerButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && user && (
            <Button
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton style={{marginRight: 10}} {...saveButtonProps} />
          )}
        </>
      }
    >
      <Steps {...stepsProps} responsive>
        <Steps.Step title="User" />
        <Steps.Step title="Shipping Address" />
        <Steps.Step title="Service Type" />
        <Steps.Step title="Order Details" />
      </Steps>

      <Form
        {...formProps}
        style={{marginTop: 30}}
        layout="vertical"
        initialValues={{
          verified: false,
          hasCustoms: true,
          'shippingAddress.phoneCountry': 'US',
          'shippingAddress.country': 'US',
          serviceType: 'LITE',
        }}
      >
        {formList[current]}
      </Form>
    </Create>
  );
};
