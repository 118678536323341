import React from 'react';
import {IInvoice, IInvoiceLine} from '../../../../interfaces';
import {Space, Table, Typography} from 'antd';
import {InvoiceLineType} from '../../../../layout/components';
import {List, NumberField} from '@refinedev/antd';

import './index.scss';
import {CellType} from 'rc-table/lib/interface';

export const InvoiceLines: React.FC<{invoice?: IInvoice}> = ({invoice}) => {
  return (
    <List title="Invoice Lines" breadcrumb={null} canCreate={false}>
      <Table
        pagination={false}
        dataSource={invoice?.lines}
        rowKey="id"
        size="small"
        scroll={{x: true}}
        className="qempo-invoice-lines-table"
        rowClassName={(record: IInvoiceLine, index: number) => {
          if (index === 0) return 'top-border';
          if (invoice) {
            if (index === invoice.lines.length - 1) return 'bottom-border';
            if (record.orderItemId != invoice.lines[index + 1].orderItemId)
              return 'bottom-border';
          }
          return '';
        }}
      >
        <Table.Column<IInvoiceLine>
          key="orderItemId"
          title={() => (
            <Typography.Text
              style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
            >
              Order Item ID
            </Typography.Text>
          )}
          render={(_, row, index: number) => {
            const props: CellType<IInvoiceLine> = {};

            if (invoice) {
              props.rowSpan = 0;

              if (
                index === 0 ||
                row.orderItemId !== invoice.lines[index - 1].orderItemId
              ) {
                for (
                  let i = 0;
                  index + i < invoice.lines.length &&
                  row.orderItemId === invoice.lines[index + i].orderItemId;
                  i++
                ) {
                  props.rowSpan = i + 1;
                }
              }
            }

            return {
              children: (
                <Typography.Text
                  style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
                >
                  {row.orderItemId}
                </Typography.Text>
              ),
              props,
            };
          }}
        />

        <Table.Column<IInvoiceLine>
          key="id"
          title="ID"
          render={(record) => (
            <Typography.Text
              style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
            >
              {record.id}
            </Typography.Text>
          )}
        />

        <Table.Column<IInvoiceLine>
          key="invoiceLineType"
          title="Type"
          render={(record) => <InvoiceLineType type={record.invoiceLineType} />}
        />

        <Table.Column<IInvoiceLine>
          key="name"
          title="Name"
          render={(record) => (
            <Typography.Text
              style={{wordBreak: 'break-word', display: 'block', minWidth: 250}}
            >
              {record.name}
            </Typography.Text>
          )}
        />
        <Table.Column<IInvoiceLine>
          key="unitPrice"
          title={() => (
            <Typography.Text
              style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
            >
              Unit Price
            </Typography.Text>
          )}
          render={(record: IInvoiceLine) => (
            <NumberField
              value={record.unitPrice}
              options={{
                style: 'currency',
                currency: 'USD',
              }}
              style={{
                whiteSpace: 'nowrap',
                display: 'block',
                textAlign: 'right',
                color: parseFloat(record.unitPrice) < 0 ? 'red' : 'inherit',
              }}
            />
          )}
        />
        <Table.Column<IInvoiceLine>
          key="quantity"
          title="Quantity"
          align="center"
          render={(record) => (
            <Typography.Text style={{wordBreak: 'inherit'}}>
              {record.quantity}
            </Typography.Text>
          )}
        />
        <Table.Column<IInvoiceLine>
          key="price"
          title="Price"
          fixed="right"
          render={(record: IInvoiceLine) => (
            <NumberField
              value={record.price}
              options={{
                style: 'currency',
                currency: 'USD',
              }}
              style={{
                whiteSpace: 'nowrap',
                display: 'block',
                textAlign: 'right',
                color: parseFloat(record.price) < 0 ? 'red' : 'inherit',
              }}
            />
          )}
        />
      </Table>
      <Space
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          padding: '8px',
          borderBottom: 'solid 2px black',
        }}
      >
        <Typography.Text
          style={{
            wordBreak: 'inherit',
            whiteSpace: 'nowrap',
            fontWeight: 700,
          }}
        >
          Total:
        </Typography.Text>
        {invoice && (
          <NumberField
            value={invoice.summary.total}
            options={{
              style: 'currency',
              currency: 'USD',
            }}
            style={{whiteSpace: 'nowrap', display: 'block', textAlign: 'right'}}
          />
        )}
      </Space>
    </List>
  );
};
