import React from 'react';
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@refinedev/core';
import {List, EditButton, useTable} from '@refinedev/antd';
import {IOrder, IUser} from '../../../interfaces';
import {Card, Col, Row, Space, Table, Typography} from 'antd';
import {Emoji} from '../../../layout/components';
import {IUserFilterVariables, UserFilter} from '../components/user-filter';

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const {tableProps, searchFormProps, filters} = useTable<
    IUser,
    HttpError,
    IUserFilterVariables
  >({
    resource: 'users',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    onSearch: (params: IUserFilterVariables) => {
      const filters: CrudFilters = [];
      const {email} = params;

      filters.push({
        field: 'email',
        operator: 'eq',
        value: email,
      });

      return filters;
    },
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col /*xl={6} lg={24}*/ xs={24}>
          <Card title="Filter">
            <UserFilter formProps={searchFormProps} filters={filters || []} />
          </Card>
        </Col>
        <Col /*xl={18}*/ xs={24}>
          <List>
            <Table {...tableProps} rowKey="id">
              <Table.Column<IUser>
                key="id"
                title="ID"
                render={(record) => (
                  <Space>
                    <Typography.Text style={{wordBreak: 'inherit'}}>
                      {record.id}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column<IUser>
                key="email"
                title="Email"
                render={(record) => (
                  <Space>
                    <Typography.Text style={{wordBreak: 'inherit'}}>
                      {record.email}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column<IUser>
                key="firstName"
                title="First name"
                render={(record) => (
                  <Space>
                    <Typography.Text style={{wordBreak: 'inherit'}}>
                      {record.firstName}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column<IUser>
                key="lastName"
                title="Last name"
                render={(record) => (
                  <Space>
                    <Typography.Text style={{wordBreak: 'inherit'}}>
                      {record.lastName}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column<IUser>
                key="altUserId"
                title="Alternative userId"
                render={(record) => (
                  <Space>
                    <Typography.Text style={{wordBreak: 'inherit'}}>
                      {record.altUserId}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column<IOrder>
                fixed="right"
                title="Actions"
                align="center"
                render={(_, record) => (
                  <Space>
                    <EditButton
                      icon={<Emoji emoji="✏️" />}
                      hideText
                      size="middle"
                      recordItemId={record.id}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
