import React, {useState} from 'react';
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@refinedev/core';
import {List, ShowButton, useTable} from '@refinedev/antd';
import {IOrder} from '../../../interfaces';
import {
  Card,
  Col,
  Row,
  Space,
  Table,
  Typography,
  List as AntList,
  Tag,
  Button,
} from 'antd';
import {
  IOrderFilterVariables,
  OrderBeforeDate,
  OrderFilter,
} from '../components';
import {
  OrderItemState,
  OrderItemsState,
  OrderState,
  Emoji,
} from '../../../layout/components';
import {dateFmt} from '../../../utils/date';
import {OrderServiceType} from '../../../layout/components/order-service-type';
import {OrderAssignLogisticModal} from '../components/order-assign-logistic-modal';

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const {tableProps, searchFormProps, tableQueryResult, filters} = useTable<
    IOrder,
    HttpError,
    IOrderFilterVariables
  >({
    resource: 'orders',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    onSearch: (params: IOrderFilterVariables) => {
      const filters: CrudFilters = [];
      const {orderId, purchaserId, state, paidAt} = params;

      filters.push({
        field: 'order_id',
        operator: 'eq',
        value: orderId,
      });

      filters.push({
        field: 'purchaser_id',
        operator: 'eq',
        value: purchaserId,
      });

      filters.push({
        field: 'state',
        operator: 'in',
        value: state,
      });

      filters.push(
        {
          field: 'paidAt',
          operator: 'gte',
          value: paidAt ? paidAt[0].startOf('day').toISOString() : undefined,
        },
        {
          field: 'paidAt',
          operator: 'lte',
          value: paidAt ? paidAt[1].endOf('day').toISOString() : undefined,
        }
      );

      return filters;
    },
  });

  const {refetch} = tableQueryResult;

  const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>();
  const [openAssignModal, setOpenAssignModal] = useState(false);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col /*xl={6} lg={24}*/ xs={24}>
          <Card title="Filter">
            <OrderFilter formProps={searchFormProps} filters={filters || []} />
          </Card>
        </Col>
        <Col /*xl={18}*/ xs={24}>
          <List canCreate={true}>
            <Table
              {...tableProps}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <AntList
                      itemLayout="horizontal"
                      dataSource={record.items}
                      renderItem={(i) => (
                        <AntList.Item>
                          <AntList.Item.Meta
                            style={{alignItems: 'center'}}
                            /*avatar={
                            <Image
                              width={128}
                              height={128}
                              src={i.imageUrl || i.image?.publicUrl}
                              draggable={false}
                              style={{objectFit: 'scale-down', marginRight: 16}}
                            />
                          }*/
                            title={
                              <>
                                <Tag>{i.id}</Tag>
                                {i.name}
                              </>
                            }
                            description={
                              <OrderItemState
                                style={{margin: '4px 0 0 0'}}
                                state={i.state}
                              />
                            }
                          />
                        </AntList.Item>
                      )}
                    />
                  );
                },
              }}
            >
              <Table.Column<IOrder>
                key="id"
                title="ID"
                render={(record) => (
                  <Space>
                    <Typography.Text
                      style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
                    >
                      {record.id}
                    </Typography.Text>
                  </Space>
                )}
              />

              <Table.Column<IOrder>
                key="logistic"
                title="Logistic"
                align="center"
                render={(record: IOrder) => (
                  <Space>
                    {record.logisticType === 'COURIER' && (
                      <Emoji size={18} emoji="📦" />
                    )}
                    {record.logisticType === 'FLIGHT' && (
                      <Emoji size={18} emoji="✈️" />
                    )}
                  </Space>
                )}
              />

              {/*<Table.Column<IOrder>
              key="firstItem"
              title="First item"
              align="center"
              render={(record: IOrder) => (
                <>
                  {record.items[0] && (
                    <Image
                      width={128}
                      height={128}
                      src={
                        record.items[0].imageUrl ||
                        record.items[0].image?.publicUrl
                      }
                      draggable={false}
                      style={{objectFit: 'scale-down', marginRight: 16}}
                    />
                  )}
                  {record.items[0] && (
                    <Typography.Text
                      ellipsis
                      style={{
                        width: 250,
                        verticalAlign: 'middle',
                        textAlign: 'left',
                        marginLeft: 16,
                      }}
                    >
                      {record.items[0].name}
                    </Typography.Text>
                  )}
                </>
              )}
            />*/}

              <Table.Column<IOrder>
                key="serviceType"
                title="ServiceType"
                align="center"
                render={(record) => (
                  <Space>
                    <Typography.Text
                      style={{
                        wordBreak: 'inherit',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <OrderServiceType serviceType={record.serviceType} />
                    </Typography.Text>
                  </Space>
                )}
              />

              <Table.Column<IOrder>
                key="state"
                title="State"
                align="center"
                render={(record) => (
                  <Space>
                    <OrderState state={record.state} />
                  </Space>
                )}
              />

              <Table.Column<IOrder>
                key="itemsState"
                title="Items state"
                render={(record: IOrder) => (
                  <Space>
                    <OrderItemsState order={record} />
                  </Space>
                )}
              />

              <Table.Column<IOrder>
                key="products"
                title="Products"
                align="center"
                render={(record) => (
                  <Space>
                    <Typography.Text>{record.items.length}</Typography.Text>
                  </Space>
                )}
              />
              <Table.Column<IOrder>
                key="paidAt"
                title="Paid At"
                align="center"
                render={(record: IOrder) => (
                  <Space>
                    <Typography.Text
                      style={{
                        wordBreak: 'inherit',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {dateFmt(new Date(record.paidAt))}
                    </Typography.Text>
                  </Space>
                )}
              />
              <Table.Column<IOrder>
                key="beforeDate"
                title="Before Date"
                align="center"
                render={(record: IOrder) => (
                  <Space>
                    <OrderBeforeDate beforeDate={record.beforeDate} />
                  </Space>
                )}
              />
              <Table.Column<IOrder>
                fixed="right"
                title="Actions"
                align="center"
                render={(_, record) => (
                  <Space>
                    <ShowButton
                      icon={<Emoji emoji="👀" />}
                      hideText
                      size="middle"
                      recordItemId={record.id}
                    />
                    {!record.logisticType && (
                      <Button
                        icon={<Emoji emoji="📥" />}
                        size="middle"
                        onClick={() => {
                          setSelectedOrder(record);
                          setOpenAssignModal(true);
                        }}
                      />
                    )}
                  </Space>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
      {selectedOrder && (
        <OrderAssignLogisticModal
          order={selectedOrder as IOrder}
          open={openAssignModal}
          onOk={() => {
            refetch().then(() => setOpenAssignModal(false));
          }}
          onCancel={() => setOpenAssignModal(false)}
        />
      )}
    </>
  );
};
