import React, {useCallback, useEffect, useState} from 'react';
import {
  IAdmin1,
  IAdmin2,
  IAdmin3,
  IShippingAddress,
} from '../../../../interfaces';
import {Edit, EditButton, ShowButton, useForm} from '@refinedev/antd';
import {Card, Col, Form, Grid, Input, Row, Select, Space, Spin} from 'antd';
import {
  Emoji,
  getAdmin1Label,
  getAdmin2Label,
  getAdmin3Label,
  ShowAddress,
} from '../../../../layout/components';
import {GeographyService} from '../../../geography/service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';
import {useShow} from '@refinedev/core';

const {useBreakpoint} = Grid;

type Mode = 'EDIT' | 'SHOW';

type Props = {
  orderId: string;
};

const ShowMode: React.FC<Props> = ({orderId}) => {
  const {queryResult: orderShippingAddressQueryResult} =
    useShow<IShippingAddress>({
      resource: 'orderShippingAddresses',
      id: orderId,
    });
  const {isLoading, isFetching, isRefetching, data} =
    orderShippingAddressQueryResult || {};

  const shippingAddress = data?.data;

  if (isLoading || isFetching || isRefetching || !shippingAddress) {
    return (
      <div style={{padding: '72px 24px', textAlign: 'center'}}>
        <Spin />
      </div>
    );
  }

  return <ShowAddress address={shippingAddress} />;
};

const EditMode: React.FC<Props & {onSuccess: () => void}> = ({
  orderId,
  onSuccess,
}) => {
  const geographyService = new GeographyService(axiosInstance as AxiosInstance);

  const {
    formProps,
    saveButtonProps,
    queryResult: orderShippingAddressQueryResult,
  } = useForm<IShippingAddress>({
    resource: 'orderShippingAddresses',
    redirect: false,
    id: orderId,
    action: 'edit',
    onMutationSuccess: onSuccess,
  });
  const {isLoading, isFetching, isRefetching, data} =
    orderShippingAddressQueryResult || {};

  const shippingAddress = data?.data;

  const {xl} = useBreakpoint();

  const [country, setCountry] = useState<string>();
  const [admins1, setAdmins1] = useState<{label: string; value: string}[]>();
  const [admins2, setAdmins2] = useState<{label: string; value: string}[]>();
  const [admins3, setAdmins3] = useState<{label: string; value: string}[]>();

  const getAdmins1 = useCallback(
    (country: string) => {
      setAdmins1([]);
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin1(country).then((admins1) => {
        setAdmins1(
          admins1.map((admin1: IAdmin1) => ({
            label: admin1.name,
            value: admin1.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins2 = useCallback(
    (admin1: string) => {
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin2(admin1).then((admins2) => {
        setAdmins2(
          admins2.map((admin2: IAdmin2) => ({
            label: admin2.name,
            value: admin2.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins3 = useCallback(
    (admin2: string) => {
      geographyService.getAdmin3(admin2).then((admins2) =>
        setAdmins3(
          admins2.map((admin3: IAdmin3) => ({
            label: admin3.name,
            value: admin3.id,
          }))
        )
      );
    },
    [geographyService]
  );

  useEffect(() => {
    if (shippingAddress && !country) {
      setCountry(shippingAddress.country.code);
      getAdmins1(shippingAddress.country.code);

      setTimeout(() => {
        if (shippingAddress) {
          getAdmins2(`${shippingAddress.admin1.id}`);

          setTimeout(() => {
            getAdmins3(`${shippingAddress.admin2.id}`);
          }, 500);
        }
      }, 500);
    }
  }, [shippingAddress, country, setCountry, getAdmins1]);

  return (
    <Edit
      isLoading={isLoading || isFetching || isRefetching}
      saveButtonProps={saveButtonProps}
      breadcrumb={null}
      goBack={null}
      headerButtons={() => null}
      contentProps={{
        style: {
          margin: '-66px -24px -24px',
          boxShadow: 'none',
          paddingBlockStart: 0,
        },
      }}
      title={<div />}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          firstName: shippingAddress?.firstName,
          lastName: shippingAddress?.lastName,
          idNumber: shippingAddress?.idNumber,
          phoneCountry: shippingAddress?.phoneCountry.code,
          phone: shippingAddress?.phone,
          country: shippingAddress?.country.code,
          admin1Id: shippingAddress?.admin1.id,
          admin2Id: shippingAddress?.admin2.id,
          admin3Id: shippingAddress?.admin3?.id,
          address1: shippingAddress?.address1,
          address2: shippingAddress?.address2,
          zipCode: shippingAddress?.zipCode,
        }}
        disabled={isLoading || isFetching || isRefetching}
      >
        <Space
          direction="vertical"
          size="small"
          style={{
            width: '100%',
            textAlign: xl ? 'unset' : 'center',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Phone country"
                name="phoneCountry"
                rules={[{required: true}]}
                style={{marginBottom: 6}}
              >
                <Select
                  allowClear
                  placeholder="Phone Country"
                  options={[
                    {label: 'United States', value: 'US'},
                    {label: 'Peru', value: 'PE'},
                    {label: 'Colombia', value: 'CO'},
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Select
                  allowClear
                  placeholder="Country"
                  options={[
                    {label: 'United States', value: 'US'},
                    {label: 'Peru', value: 'PE'},
                    {label: 'Colombia', value: 'CO'},
                  ]}
                  onChange={(country: string) => {
                    setCountry(country);
                    getAdmins1(country);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label={getAdmin1Label(country)}
                name="admin1Id"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Select
                  allowClear
                  placeholder="Admin1"
                  options={admins1}
                  showSearch
                  filterOption={(inputValue, option) => {
                    if (option && typeof option.label === 'string') {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }

                    return false;
                  }}
                  onChange={(admin1: number) => {
                    getAdmins2(`${admin1}`);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label={getAdmin2Label(country)}
                name="admin2Id"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Select
                  allowClear
                  placeholder="Admin2"
                  options={admins2}
                  disabled={!admins2 || admins2.length === 0}
                  showSearch
                  filterOption={(inputValue, option) => {
                    if (option && typeof option.label === 'string') {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }

                    return false;
                  }}
                  onChange={(admin2: number) => {
                    getAdmins3(`${admin2}`);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              {country !== 'US' && (
                <Form.Item
                  label={getAdmin3Label(country)}
                  name="admin3Id"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  style={{marginBottom: 6}}
                >
                  <Select
                    allowClear
                    placeholder="Admin3"
                    options={admins3}
                    disabled={!admins3 || admins3.length === 0}
                    showSearch
                    filterOption={(inputValue, option) => {
                      if (option && typeof option.label === 'string') {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }

                      return false;
                    }}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>

          <Form.Item
            label="Address1"
            name="address1"
            rules={[
              {
                required: true,
              },
            ]}
            style={{marginBottom: 6}}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Address2" name="address2" style={{marginBottom: 6}}>
            <Input />
          </Form.Item>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="ZipCode"
                name="zipCode"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="ID Number"
                name="idNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
    </Edit>
  );
};

export const OrderShippingAddressEdit: React.FC<Props> = ({orderId}) => {
  const [mode, setMode] = useState<Mode>('SHOW');

  return (
    <Card
      style={{minHeight: '100%'}}
      title={
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <span>
            <Emoji emoji="📍" /> Shipping Address
          </span>
          {mode === 'SHOW' && (
            <EditButton
              size="small"
              resource="shipping-addresses"
              onClick={(e) => {
                e.preventDefault();
                setMode('EDIT');
              }}
            />
          )}
          {mode === 'EDIT' && (
            <ShowButton
              icon={<Emoji emoji="👀" />}
              hideText
              size="middle"
              resource="shipping-addresses"
              onClick={(e) => {
                e.preventDefault();
                setMode('SHOW');
              }}
            />
          )}
        </div>
      }
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {mode === 'SHOW' && <ShowMode orderId={orderId} />}
        {mode === 'EDIT' && (
          <EditMode orderId={orderId} onSuccess={() => setMode('SHOW')} />
        )}
      </Space>
    </Card>
  );
};
