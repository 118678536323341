import React, {ChangeEventHandler, useEffect, useState} from 'react';
import {Button, Col, Grid, Input, message, Row, Segmented, Space} from 'antd';
import {Emoji} from '../../../../layout/components';
import {CopyOutlined} from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Hashids from 'hashids';

const {useBreakpoint} = Grid;

export type EncodeType = 'encode' | 'decode';

const hashids = new Hashids(
  '21d552e5ad1e34d5189fa4e1ff3b646d',
  8,
  '0123456789ABCDEF'
);

const EncoderDecoder: React.FC<{type: EncodeType}> = ({type}) => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const {xs} = useBreakpoint();

  useEffect(() => {
    setInput('');
    setOutput('');
  }, [type]);

  const decode = (encodedId = ''): number | undefined => {
    const sp = encodedId.split('-');
    let id = sp[0];

    if (sp.length === 2) {
      id = sp[1];
    }

    try {
      return hashids.decode(id)[0] as number;
    } catch (e) {
      console.error(e);
    }
  };

  const encode = (rawId: number): string => {
    return hashids.encode(rawId);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const i = e.target.value;
    setInput(i);

    if (type === 'decode') {
      const rawId = decode(i);
      if (typeof rawId === 'number') {
        setOutput(`${rawId}`);
      } else {
        setOutput('');
      }
    } else if (type === 'encode') {
      const num = parseInt(i);
      console.log('num', num);
      if (!isNaN(num)) {
        const encodedId = encode(num);
        console.log('encodedId', encodedId);
        setOutput(encodedId);
      } else {
        setOutput('');
      }
    }
  };

  const onCopy = async () => {
    await messageApi.open({
      type: 'success',
      content: 'Output copied successfully',
    });
  };

  return (
    <>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={11}>
          {type === 'decode' && (
            <Input
              addonBefore="ID"
              placeholder="OR-XXXXXXXX"
              value={input}
              onChange={onChange}
            />
          )}
          {type === 'encode' && (
            <Input
              addonBefore="Raw ID"
              placeholder=""
              value={input}
              onChange={onChange}
            />
          )}
        </Col>
        <Col xs={24} sm={2} style={{textAlign: 'center'}}>
          <Emoji size={24} emoji={xs ? '⬇️' : '➡️'} />
        </Col>
        <Col xs={24} sm={11}>
          <Space.Compact style={{width: '100%'}}>
            <Input
              addonBefore={type === 'encode' ? 'XX-' : undefined}
              readOnly
              disabled
              value={output}
            />
            <CopyToClipboard text={output} onCopy={onCopy}>
              <Button type="primary" disabled={!output}>
                <CopyOutlined />
              </Button>
            </CopyToClipboard>
          </Space.Compact>
        </Col>
      </Row>
    </>
  );
};

export const IdEncoder: React.FC = () => {
  const [encodeType, setEncodeType] = useState<EncodeType>('decode');

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} style={{textAlign: 'center'}}>
          <Segmented
            value={encodeType}
            onChange={setEncodeType}
            options={['decode', 'encode']}
          />
        </Col>
      </Row>
      <br />
      <br />
      <EncoderDecoder type={encodeType} />
    </>
  );
};
