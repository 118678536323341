import React, {useCallback, useEffect, useState} from 'react';
import {Form, Select} from 'antd';
import {IAdmin1, IAdmin2, IAdmin3} from 'interfaces';
import {GeographyService} from 'modules/geography/service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';

type Props = {
  title: string;
  formItemNames: {
    country: string;
    admin1Id: string;
    admin2Id: string;
    admin3Id: string;
  };
};

export const OrderGeography: React.FC<Props> = ({title, formItemNames}) => {
  const geographyService = new GeographyService(axiosInstance as AxiosInstance);

  const [admins1, setAdmins1] = useState<{label: string; value: string}[]>();
  const [admins2, setAdmins2] = useState<{label: string; value: string}[]>();
  const [admins3, setAdmins3] = useState<{label: string; value: string}[]>();

  const [country, setCountry] = useState('US');

  const getAdmins1 = useCallback(
    (country: string) => {
      setAdmins1([]);
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin1(country).then((admins1) => {
        setAdmins1(
          admins1.map((admin1: IAdmin1) => ({
            label: admin1.name,
            value: admin1.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins2 = useCallback(
    (admin1: string) => {
      setAdmins2([]);
      setAdmins3([]);
      geographyService.getAdmin2(admin1).then((admins2) => {
        setAdmins2(
          admins2.map((admin2: IAdmin2) => ({
            label: admin2.name,
            value: admin2.id,
          }))
        );
      });
    },
    [geographyService]
  );

  const getAdmins3 = useCallback(
    (admin2: string) => {
      geographyService.getAdmin3(admin2).then((admins2) =>
        setAdmins3(
          admins2.map((admin3: IAdmin3) => ({
            label: admin3.name,
            value: admin3.id,
          }))
        )
      );
    },
    [geographyService]
  );

  useEffect(() => {
    getAdmins1('US');
  }, [setAdmins1]);

  return (
    <>
      <h3>{title.toLocaleUpperCase()}</h3>

      <Form.Item
        label="Country"
        name={formItemNames.country}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          allowClear
          placeholder="Country"
          options={[
            {label: 'United States', value: 'US'},
            {label: 'Peru', value: 'PE'},
            {label: 'Colombia', value: 'CO'},
          ]}
          onChange={(country: string) => {
            setCountry(country);
            getAdmins1(country);
          }}
        />
      </Form.Item>
      <Form.Item
        label="Admin1"
        name={formItemNames.admin1Id}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          allowClear
          placeholder="Admin1"
          options={admins1}
          showSearch
          filterOption={(inputValue, option) => {
            if (option && typeof option.label === 'string') {
              return (
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0
              );
            }

            return false;
          }}
          onChange={(admin1: number) => {
            getAdmins2(`${admin1}`);
          }}
        />
      </Form.Item>

      <Form.Item
        label="Admin2"
        name={formItemNames.admin2Id}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          allowClear
          placeholder="Admin2"
          options={admins2}
          disabled={!admins2 || admins2.length === 0}
          showSearch
          filterOption={(inputValue, option) => {
            if (option && typeof option.label === 'string') {
              return (
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0
              );
            }

            return false;
          }}
          onChange={(admin2: number) => {
            getAdmins3(`${admin2}`);
          }}
        />
      </Form.Item>

      {country !== 'US' && (
        <Form.Item
          label="Admin3"
          name={formItemNames.admin3Id}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Admin3"
            options={admins3}
            disabled={!admins3 || admins3.length === 0}
            showSearch
            filterOption={(inputValue, option) => {
              if (option && typeof option.label === 'string') {
                return (
                  option.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                );
              }

              return false;
            }}
          />
        </Form.Item>
      )}
    </>
  );
};
