import {CrudFilters, getDefaultFilter} from '@refinedev/core';
import {Button, Col, Form, FormProps, Input, Row} from 'antd';
import React from 'react';
import {SearchOutlined} from '@ant-design/icons';

export interface IUserFilterVariables {
  email?: string;
}
export const UserFilter: React.FC<{
  formProps: FormProps;
  filters: CrudFilters;
}> = ({formProps, filters}) => {
  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        email: getDefaultFilter('email', filters),
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item label="Email" name="email">
            <Input placeholder="user@domain.com" prefix={<SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
