import React, {useContext, useState} from 'react';
import {useGetIdentity} from '@refinedev/core';
import {
  Layout as AntdLayout,
  Avatar,
  Space,
  Switch,
  Typography,
  theme,
  Button,
  Grid,
} from 'antd';
import {ColorModeContext} from '../../contexts/color-mode';
import {IUser} from '../../../authProvider';
import {IdEncoderModal} from '../../../modules/id-encoder/components/id-encoder-modal';

const {Text} = Typography;
const {useToken} = theme;
const {useBreakpoint} = Grid;

export const Header: React.FC = () => {
  const {token} = useToken();
  const {data: user} = useGetIdentity<IUser>();
  const {mode, setMode} = useContext(ColorModeContext);
  const [idEncoderModalOpen, setIdEncoderModalOpen] = useState(false);
  const {xs} = useBreakpoint();

  return (
    <>
      <IdEncoderModal
        open={idEncoderModalOpen}
        setOpen={setIdEncoderModalOpen}
      />
      <AntdLayout.Header
        style={{
          backgroundColor: token.colorBgElevated,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0px 24px',
          height: '64px',
        }}
      >
        <Space size="large">
          <Button type="primary" onClick={() => setIdEncoderModalOpen(true)}>
            Decode ID
          </Button>
          <Switch
            checkedChildren="🌛"
            unCheckedChildren="🔆"
            onChange={() => setMode(mode === 'light' ? 'dark' : 'light')}
            defaultChecked={mode === 'dark'}
          />
          <Space style={{marginLeft: '8px'}} size="middle">
            {user?.name && !xs && <Text strong>{user.name}</Text>}
            {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
          </Space>
        </Space>
      </AntdLayout.Header>
    </>
  );
};
