import React, {useCallback, useState} from 'react';
import {IInvoice, IOrder, IPayment} from '../../../../interfaces';
import {Button, Modal, Space, Table, Tag, Typography} from 'antd';
import {List, NumberField} from '@refinedev/antd';
import {PaymentState} from '../../../../layout/components';
import {dateFmt} from '../../../../utils/date';
import {DollarCircleOutlined, PlusSquareOutlined} from '@ant-design/icons';
import {InvoiceService} from '../../../invoice/service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';
import {PaymentCreateDrawer} from '../payment-create-drawer';

const invoiceService = new InvoiceService(axiosInstance as AxiosInstance);

export const Payments: React.FC<{
  order?: IOrder;
  invoice?: IInvoice;
  refetch: () => Promise<any>;
}> = ({order, invoice, refetch}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [refundModalOpen, setRefundModalOpen] = useState<{
    open: boolean;
    paymentId?: string;
  }>({open: false});
  const [loadingRefund, setLoadingRefund] = useState<boolean>(false);

  const refundPayment = useCallback(() => {
    if (invoice && refundModalOpen.paymentId) {
      setLoadingRefund(true);
      invoiceService
        .payPayment(refundModalOpen.paymentId)
        .then(() => {
          setLoadingRefund(false);
          refetch().then(() => {
            setRefundModalOpen({open: false});
          });
        })
        .catch(() => setLoadingRefund(false));
    }
  }, [invoice, refundModalOpen, refetch, setLoadingRefund, setRefundModalOpen]);

  return (
    <>
      <PaymentCreateDrawer
        order={order}
        invoice={invoice}
        open={drawerOpen}
        refetch={refetch}
        setOpen={setDrawerOpen}
      />
      <List
        title="Payments"
        breadcrumb={null}
        canCreate={false}
        headerButtons={() => (
          <>
            <Button
              type="primary"
              icon={<PlusSquareOutlined />}
              iconPosition="start"
              onClick={() => setDrawerOpen(true)}
              disabled={!order}
            >
              Create
            </Button>
          </>
        )}
      >
        <Table
          pagination={false}
          dataSource={invoice?.payments}
          rowKey="id"
          size="small"
          scroll={{x: true}}
        >
          <Table.Column<IPayment>
            key="id"
            title="ID"
            render={(record: IPayment) => (
              <Typography.Text
                style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
              >
                {record.id}
              </Typography.Text>
            )}
          />

          <Table.Column<IPayment>
            key="paymentMethod"
            title="Method"
            render={(record: IPayment) => <Tag>{record.paymentMethod}</Tag>}
          />

          <Table.Column<IPayment>
            key="paymentProcessor"
            title="Processor"
            render={(record: IPayment) => <Tag>{record.paymentProcessor}</Tag>}
          />

          <Table.Column<IPayment>
            key="state"
            title="State"
            render={(record: IPayment) => <PaymentState state={record.state} />}
          />

          <Table.Column<IPayment>
            key="createdAt"
            title="Created at"
            render={(record: IPayment) => (
              <Typography.Text
                style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
              >
                {dateFmt(new Date(record.createdAt))}
              </Typography.Text>
            )}
          />

          <Table.Column<IPayment>
            key="total"
            title="Total"
            fixed="right"
            render={(record: IPayment) => (
              <NumberField
                value={record.total}
                options={{
                  style: 'currency',
                  currency: 'USD',
                }}
                style={{
                  whiteSpace: 'nowrap',
                  color: parseFloat(record.total) < 0 ? 'red' : 'inherit',
                }}
              />
            )}
          />

          <Table.Column<IPayment>
            fixed="right"
            title="Actions"
            render={(record: IPayment) => {
              if (record.state === 'REFUND_PENDING') {
                return (
                  <Space>
                    <Button
                      type="primary"
                      size="small"
                      block
                      onClick={() =>
                        setRefundModalOpen({open: true, paymentId: record.id})
                      }
                    >
                      <DollarCircleOutlined />
                      Refund
                    </Button>
                  </Space>
                );
              } else if (record.state === 'PENDING') {
                return (
                  <Space>
                    <Button
                      type="primary"
                      size="small"
                      block
                      onClick={() =>
                        setRefundModalOpen({open: true, paymentId: record.id})
                      }
                    >
                      <DollarCircleOutlined />
                      Pay
                    </Button>
                  </Space>
                );
              }
            }}
          />
        </Table>
      </List>
      <Modal
        open={refundModalOpen.open}
        title={`Refund payment: ${refundModalOpen.paymentId}`}
        confirmLoading={loadingRefund}
        onOk={() => refundPayment()}
        onCancel={() => setRefundModalOpen({open: false})}
      >
        <Typography.Text style={{display: 'block'}}>
          Are you sure you want to refund/pay this payment?
        </Typography.Text>
      </Modal>
    </>
  );
};
