import {AxiosInstance} from 'axios';
import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';

export class InvoiceService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getInvoice(invoiceId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/checkout/invoice/${invoiceId}`;
    const {data} = await this.httpClient.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async refundInvoice(invoiceId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/checkout/invoice/${invoiceId}/refund`;
    const {data} = await this.httpClient.post(`${url}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }

  public async payPayment(paymentId: string) {
    const url = `${vars.baseApiUrl}/logistics/admin/checkout/payment/${paymentId}/pay`;
    const {data} = await this.httpClient.post(`${url}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;
  }
}
