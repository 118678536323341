import React, {useEffect, useState} from 'react';
import {IInvoice, IOrder} from '../../../../interfaces';
import {
  Typography,
  Drawer,
  Radio,
  Steps,
  Divider,
  Card,
  Descriptions,
  Tag,
  Button,
  message,
} from 'antd';
import {PlusSquareOutlined, DeleteOutlined} from '@ant-design/icons';
import {
  PaymentCreateInvoiceLine,
  PaymentCreateInvoiceLineForm,
} from '../payment-create-invoice-line-form';

import './index.scss';
import {OrderService} from '../../service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';

const {Text} = Typography;

const USDollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const orderService = new OrderService(axiosInstance as AxiosInstance);

export const PaymentCreateDrawer: React.FC<{
  order?: IOrder;
  invoice?: IInvoice;
  refetch: () => Promise<any>;
  open: boolean;
  setOpen: (o: boolean) => void;
}> = ({order, refetch, open, setOpen}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lines, setLines] = useState<PaymentCreateInvoiceLine[]>([]);
  const [paymentType, setPaymentType] = useState<'payment' | 'payout'>(
    'payment'
  );
  const [messageApi, contextHolder] = message.useMessage();

  const onAddInvoiceLine = (value: PaymentCreateInvoiceLine) => {
    setLines((s) => [...s, value]);
  };

  const onDeleteInvoiceLine = (index: number) => {
    setLines((l) => {
      return l.filter((_, i) => i !== index);
    });
  };

  const onCreatePayment = async () => {
    if (!lines.length) {
      messageApi.open({
        type: 'error',
        content: 'You must add at least one invoice line',
      });
      return;
    }

    setLoading(true);
    messageApi.open({
      type: 'loading',
      content: 'Creating Payment',
      duration: 0,
    });

    try {
      await orderService.createExtraPayment({
        orderId: order?.id as string,
        invoiceLines: lines.map(({invoiceLineType, orderItemId, amount}) => ({
          invoiceLineType,
          orderItemId,
          price: amount,
        })),
      });

      messageApi.destroy();
      setOpen(false);
      refetch();
    } catch (e) {
      messageApi.destroy();
      messageApi.open({
        type: 'error',
        content: 'Could not create payment',
        duration: 6,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setLines([]);
    }
  }, [open]);

  return (
    <>
      {contextHolder}
      <Drawer
        closable
        destroyOnClose
        title={<p style={{margin: 0}}>Create payment</p>}
        placement="right"
        open={open}
        size="large"
        onClose={() => {
          if (!loading) {
            setOpen(false);
          }
        }}
      >
        <Steps
          current={-1}
          direction="vertical"
          items={[
            {
              title: 'Payment type',
              description: (
                <div className="step-content-wrap">
                  <Radio.Group
                    value={paymentType}
                    disabled={loading}
                    onChange={(e) => setPaymentType(e.target.value)}
                    size="large"
                  >
                    <Radio.Button
                      key="payment"
                      value="payment"
                      style={{height: '80px'}}
                    >
                      Payment <br />
                      <Text type="secondary">(client need to pay)</Text>
                    </Radio.Button>
                    <Radio.Button
                      key="payout"
                      value="payout"
                      style={{height: '80px'}}
                    >
                      Payout <br />
                      <Text type="secondary">
                        (we need to reimburse the client)
                      </Text>
                    </Radio.Button>
                  </Radio.Group>
                </div>
              ),
            },
            {
              title: 'Invoice lines',
              description: (
                <div className="step-content-wrap">
                  <PaymentCreateInvoiceLineForm
                    order={order}
                    paymentType={paymentType}
                    onAddInvoiceLine={onAddInvoiceLine}
                    disabled={loading}
                  />
                </div>
              ),
            },
          ]}
        />
        <Divider plain>Invoice Lines</Divider>
        <div className="step-content-wrap">
          {lines &&
            lines.map((l, index) => (
              <Card
                key={`${l.invoiceLineType}-${l.orderItemId}`}
                title={`Payment type: ${l.paymentType.toUpperCase()}`}
                extra={
                  <Button
                    type="primary"
                    danger
                    shape="circle"
                    disabled={loading}
                    onClick={() => onDeleteInvoiceLine(index)}
                    icon={<DeleteOutlined />}
                  />
                }
                bordered={false}
              >
                <Descriptions
                  column={1}
                  items={[
                    {
                      key: '1',
                      label: 'Order Item',
                      children: l.orderItemId ? (
                        <Tag color="success">{l.orderItemId}</Tag>
                      ) : (
                        <></>
                      ),
                    },
                    {
                      key: '2',
                      label: 'Invoice Line Type',
                      children: (
                        <Tag color="processing">{l.invoiceLineType}</Tag>
                      ),
                    },
                    {
                      key: '3',
                      label: 'Amount',
                      children: USDollarFormatter.format(parseFloat(l.amount)),
                    },
                  ]}
                />
              </Card>
            ))}
        </div>
        <Divider plain />
        <Button
          type="primary"
          size="large"
          block
          onClick={onCreatePayment}
          icon={<PlusSquareOutlined />}
          iconPosition="start"
          disabled={loading}
        >
          Create payment
        </Button>
      </Drawer>
    </>
  );
};
