import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Select, Collapse, Divider} from 'antd';
import {ICategory, ISubCategory} from 'interfaces';

const {Panel} = Collapse;

type Props = {
  categories: ICategory[] | undefined;
  orderItemIndex: number;
};

export const OrderItemCreate: React.FC<Props> = ({
  categories,
  orderItemIndex,
}) => {
  const [subCategories, setSubCategories] =
    useState<{label: string; value: string}[]>();
  const [categoriesForm, setCategoriesForm] =
    useState<{label: string; value: string}[]>();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [panelHeader, setPanelHeader] = useState<string>('');

  const getSubCategories = (categoryId: string) => {
    const category = categories?.find(
      (category: ICategory) => category.id === categoryId
    );
    setSubCategories(
      category?.subCategories.map((subCategory: ISubCategory) => ({
        label: subCategory.name,
        value: subCategory.id,
      }))
    );
  };

  useEffect(() => {
    setCategoriesForm(
      categories?.map((category: ICategory) => ({
        label: category.name,
        value: category.id,
      }))
    );
  }, [categories]);

  const handlePanelCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Col xs={24} sm={24} md={24}>
      <Collapse
        bordered={false}
        onChange={handlePanelCollapse}
        expandIconPosition="right"
      >
        <Panel header={panelHeader || 'Order item'} key={orderItemIndex}>
          <Divider orientation="center">Item Details</Divider>
          <Form.Item
            label="Name"
            name={['items', orderItemIndex, 'name']}
            rules={[{required: true, message: 'Please enter the name'}]}
          >
            <Input onChange={(event) => setPanelHeader(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Url"
            name={['items', orderItemIndex, 'url']}
            rules={[{required: true, message: 'Please enter the URL'}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Price"
            name={['items', orderItemIndex, 'price']}
            rules={[{required: true, message: 'Please enter the price'}]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Detail" name={['items', orderItemIndex, 'detail']}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Quantity"
            name={['items', orderItemIndex, 'quantity']}
            rules={[{required: true, message: 'Please enter the quantity'}]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Weight"
            name={['items', orderItemIndex, 'weight']}
            rules={[{required: true, message: 'Please enter the weight'}]}
          >
            <Input type="number" />
          </Form.Item>
          {/*<Form.Item
            label="Original Packaging"
            name={['items', orderItemIndex, 'originalPackaging']}
            rules={[{ required: true, message: 'Please select original packaging' }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
        </Form.Item>*/}
          <Form.Item
            label="Category"
            name={['items', orderItemIndex, 'categoryId']}
            rules={[{required: true, message: 'Please select a category'}]}
          >
            <Select
              allowClear
              placeholder="Category"
              options={categoriesForm}
              showSearch
              filterOption={(inputValue, option) => {
                if (option && typeof option.label == 'string') {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  );
                }
                return false;
              }}
              onChange={(categoryId: string) => {
                getSubCategories(categoryId);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Sub Category"
            name={['items', orderItemIndex, 'subCategoryId']}
          >
            <Select
              allowClear
              placeholder="Sub Category"
              options={subCategories}
              disabled={!subCategories || subCategories.length === 0}
              showSearch
              filterOption={(inputValue, option) => {
                if (option && typeof option.label == 'string') {
                  return (
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  );
                }
                return false;
              }}
            />
          </Form.Item>
          <Form.Item
            label="Image Url"
            name={['items', orderItemIndex, 'imageUrl']}
            rules={[{required: true, message: 'Please the image URL'}]}
          >
            <Input />
          </Form.Item>

          <Divider orientation="center">Invoice Data</Divider>
          <Form.Item
            label="Commission"
            name={['items', orderItemIndex, 'invoiceData.commission']}
            rules={[
              {required: true, message: 'Please enter the commission value'},
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="Customs Duties"
            name={['items', orderItemIndex, 'invoiceData.customsDuties']}
            rules={[
              {
                required: true,
                message: 'Please enter the Customs Duties value',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="Warranty"
            name={['items', orderItemIndex, 'invoiceData.warranty']}
            rules={[{required: true, message: 'Please enter Warranty'}]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="Qempo"
            name={['items', orderItemIndex, 'invoiceData.qempo']}
            rules={[{required: true, message: 'Please enter Qempo'}]}
          >
            <Input type="number" />
          </Form.Item>
        </Panel>
      </Collapse>
    </Col>
  );
};
