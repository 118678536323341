import React from 'react';
import {Modal} from 'antd';
import {IdEncoder} from '../id-encoder';

export const IdEncoderModal: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({open, setOpen}) => {
  return (
    <Modal
      title="ID Encoder"
      open={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{style: {display: 'none'}}}
    >
      <IdEncoder />
      <br />
      <br />
      <br />
    </Modal>
  );
};
