import {Card, Col, Row, Space, Typography} from 'antd';
import {Emoji, ShowUser} from '../../../../layout/components';
import React from 'react';
import {IOrder} from '../../../../interfaces';

export const OrderPurchaser: React.FC<{
  order: IOrder;
}> = ({order}) => (
  <Card
    title={
      <Space
        direction="horizontal"
        style={{display: 'flex', justifyContent: 'space-between'}}
      >
        <Typography.Text>
          <Emoji emoji="👤" style={{marginRight: 8}} />
          Purchaser
        </Typography.Text>
      </Space>
    }
  >
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ShowUser user={order.purchaser} />
      </Col>
    </Row>
  </Card>
);
