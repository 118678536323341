import {CrudFilters, getDefaultFilter} from '@refinedev/core';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Row,
  Select,
} from 'antd';
import React, {useMemo} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import dayjs, {Dayjs} from 'dayjs';

export interface IOrderFilterVariables {
  orderId?: string;
  purchaserId?: string;
  paidAt?: [Dayjs, Dayjs];
  state?: string[];
}
export const OrderFilter: React.FC<{
  formProps: FormProps;
  filters: CrudFilters;
}> = ({formProps, filters}) => {
  const {RangePicker} = DatePicker;

  const paidAt = useMemo(() => {
    const start = getDefaultFilter('paidAt', filters, 'gte');
    const end = getDefaultFilter('paidAt', filters, 'lte');

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        purchaserId: getDefaultFilter('purchaser_id', filters),
        orderId: getDefaultFilter('order_id', filters),
        state: getDefaultFilter('state', filters, 'in'),
        paidAt,
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item label="Order Id" name="orderId">
            <Input placeholder="OR-XXXXXXXX" prefix={<SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item label="Purchaser Id" name="purchaserId">
            <Input placeholder="U-XXXXXXXX" prefix={<SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item label="State" name="state">
            <Select
              allowClear
              mode="multiple"
              placeholder="State"
              options={[
                {label: 'CREATED', value: 'CREATED'},
                {label: 'PAID', value: 'PAID'},
                {label: 'COMPLETED', value: 'COMPLETED'},
                {label: 'CANCELED', value: 'CANCELED'},
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item label="Paid At" name="paidAt">
            <RangePicker style={{width: '100%'}} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
