import React from 'react';
import {IResourceComponentsProps} from '@refinedev/core';
import {useForm, Create} from '@refinedev/antd';
import {Col, Form, Grid, Input, Row, Space} from 'antd';
import {IUser} from '../../../interfaces';

const {useBreakpoint} = Grid;

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const {formProps, queryResult, saveButtonProps} = useForm<IUser>({
    resource: 'users',
  });

  const {xl} = useBreakpoint();

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} style={{marginTop: 30}} layout="vertical">
        <Space
          direction="vertical"
          size="small"
          style={{
            width: '100%',
            textAlign: xl ? 'unset' : 'center',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Alt user id"
                name="altUserId"
                rules={[
                  {
                    required: false,
                  },
                ]}
                style={{marginBottom: 6}}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    type: 'string',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
    </Create>
  );
};
